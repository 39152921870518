import UserTable from "./components/UserTable";
import SidePanel from "./components/SidePanel";
import TopBar from "./components/TopBar";
import "./index.css";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <>
      <div className="moukha">
        <TopBar />
        {/* <SidePanel /> */}
        <div className="moukha__table">
          <UserTable />
        </div>
      </div>
    </>
  );
}

export default App;
