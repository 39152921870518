import React, { useState, useEffect } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Inject,
  Sort,
  Resize,
  Filter,
  Search,
  Edit,
} from "@syncfusion/ej2-react-grids";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import FileUploadModal from "./FileUploadModal";
import Papa from "papaparse";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Group } from "@syncfusion/ej2-react-grids";
import { Toolbar } from "@syncfusion/ej2-react-grids";
import "../../styles/UserTable/userTable.css";
import "./Modal.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DataManager, Query } from "@syncfusion/ej2-data";
const buttonStyle = {
  backgroundColor: "grey",
  color: "white",
  fontFamily: "Public Sans, sans-serif",
  borderRadius: "4px",
  border: "none",
  cursor: "pointer",
  width: "fit-content",
  boxSizing: "border-box",
  marginBottom: 0,
};
const AddRowModal = ({ isOpen, onClose, onSave, businessOptions }) => {
  const [formData, setFormData] = useState({
    contactId: "",
    name: "",
    email: "",
    orgName: "",
    phone: "",
    domain: "",
    role: "",
    isActive: false,
    discounts: "",
    linkedin: "",
    isDp: false,
    isRequested: false,
    natureOfBusiness: "",
    NatureOfBusinessId: "",
    // Add other properties with default values if needed
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Check if the dropdown value has changed
    const dropdownValueChanged = name === "natureOfBusiness";

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
      // Use the latest values from the dropdown if it has changed; otherwise, use default values
      NatureOfBusinessId: dropdownValueChanged
        ? businessOptions.find((item) => item.natureOfBusinessName === value)
            ?.id || prevFormData.NatureOfBusinessId
        : prevFormData.NatureOfBusinessId,
      natureOfBusinessName: dropdownValueChanged
        ? value
        : prevFormData.natureOfBusinessName,
    }));
  };

  const handleSave = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Validation for phone
    const phoneRegex = /^[0-9]+$/;
    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      toast.error("Please enter a valid phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Validation for linkedin

    if (!formData.linkedin) {
      toast.error("Please enter a valid LinkedIn URL", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!formData.orgName || formData.orgName.trim().length < 2) {
      toast.error("Organization Name must be at least 2 characters long", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!formData.orgName || formData.orgName.trim().length < 2) {
      toast.error("Organization Name must be at least 2 characters long", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const orgNameField = document.getElementById("orgName");
    if (!formData.orgName) {
      orgNameField?.classList?.add("empty-field");
    } else {
      orgNameField?.classList?.remove("empty-field");
    }
    // Continue with saving data if all validations pass
    onSave(formData);
    onClose();
  };

  return (
    <div className={`modal ${isOpen ? "show" : "hide"}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Add User</h2>

        <div className="form-columns">
          {/* Left Column */}
          <div className="form-column">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
            />
            <div className="email-input-container">
              <input
                type="text"
                name="email"
                className={`required ${!formData.email ? "empty-field" : ""}`}
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
              {!formData.email && <span className="error-indicator">*</span>}
            </div>
            <div className="email-input-container">
              <input
                type="text"
                name="orgName"
                value={formData.orgName}
                className={`required ${!formData.orgName ? "empty-field" : ""}`}
                onChange={handleChange}
                placeholder="Organization Name"
              />
              {!formData.orgName && <span className="error-indicator">*</span>}
            </div>
            <div className="email-input-container">
              {" "}
              <input
                type="number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                style={{ marginBottom: "15px" }}
                min="0"
              />
              {!formData.phone && <span className="error-indicator">*</span>}
            </div>
          </div>

          {/* Right Column */}
          <div className="form-column">
            <input
              type="text"
              name="domain"
              value={formData.domain}
              onChange={handleChange}
              placeholder="Domain"
              style={{ marginBottom: "15px" }}
            />
            <input
              type="text"
              name="role"
              value={formData.role}
              onChange={handleChange}
              placeholder="Role"
              style={{ marginBottom: "15px" }}
            />
            <DropDownListComponent
              placeholder="Select Nature of Business"
              dataSource={businessOptions.map(
                (option) => option.natureOfBusinessName
              )}
              value={
                formData.natureOfBusiness ||
                businessOptions[14]?.natureOfBusinessName
              }
              name="natureOfBusiness"
              style={{ marginBottom: "15px" }}
              change={(e) =>
                handleChange({
                  target: { name: "natureOfBusiness", value: e.value },
                })
              }
            />
            <input
              type="text"
              name="discounts"
              value={formData.discounts}
              onChange={handleChange}
              placeholder="Discounts"
            />
            <div className="linkedin-input-container">
              <input
                type="text"
                name="linkedin"
                className={`required ${
                  !formData.linkedin ? "empty-field" : ""
                }`}
                value={formData.linkedin}
                onChange={handleChange}
                placeholder="LinkedIn"
              />
              {!formData.linkedin && <span className="error-indicator">*</span>}
            </div>
          </div>
          <div className="checkbox-column">
            <div>
              <input
                type="checkbox"
                checked={formData.isDp}
                onChange={handleChange}
                style={{
                  transform: "scale(1.5)",
                  marginRight: "5px",
                  height: "0.65rem",
                  width: "0.65rem",
                }}
                name="isDp"
                id="isDp"
              />
              <label for="isDp"> Is Dp</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={formData.isRequested}
                style={{
                  transform: "scale(1.5)",
                  marginRight: "5px",
                  height: "0.65rem",
                  width: "0.65rem",
                }}
                id="isRequested"
                name="isRequested"
                onChange={handleChange}
              />
              <label for="isRequested"> Is Requested</label>
            </div>
          </div>
        </div>

        {/* Buttons to Save and Close */}
        <div className="modal-buttons">
          <button onClick={handleSave}>Save</button>
          <div style={{ marginLeft: "10px" }}></div>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

const SampleGridComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [searchTerm, setSearchTerm] = useState('');
  // const [filteredData, setFilteredData] = useState([]);
  const [refetch, setRefetch] = useState(true);

  const editOptions = { allowEditing: true };
  const [previousData, setPreviousData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMessage, setCurrentMessage] = React.useState("");
  const [nextMessage, setNextMessage] = React.useState("");
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [businessOptions, setBusinessOptions] = useState([]);
 
  // const handleFileUpload = (file) => {
  //   // Implement the logic to handle the file upload
  //   console.log("File uploaded:", file);
  //   // You can use this file data for further processing
  // };

  const handleShowFileUploadModal = () => {
    setShowFileUploadModal(true);
  };

  const handleCloseFileUploadModal = () => {
    setShowFileUploadModal(false);
  };
  const actionBegin = (args) => {
    console.log("first arga", args);
    if (args.requestType === "paging") {
      const pageMessage =
        args.currentPage > args.previousPage
          ? `You are going to switch to page ${
              parseInt(args.currentPage, 10) + 1
            }`
          : `You are going to switch to page ${args.previousPage}`;
      setNextMessage(pageMessage);
    } else if (args.requestType === "save") {
      handleGridActionBegin(args);
    }
  };
  const actionComplete = (args) => {
    if (args.requestType === "paging") {
      setCurrentMessage("Now you are in page " + args.currentPage);
      fetchData(currentPage + 1);
    }
  };

  const handleAddRow = () => {
    setShowModal(true);
  };
  const pageOptions = {
    pageSizes: ["5", "10", "25"],
    pageSizes: true,
  };
  const [groupSettings, setGroupSettings] = useState([
    { columns: ["isActive"] },
  ]); // Initial group setting

  // const handleGroupSettingsChange = (newGroupSettings) => {
  //   setGroupSettings(newGroupSettings);
  // };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveModal = (formData) => {
    const defaultContactId = "00000000-0000-0000-0000-000000000000";
    if (!formData.natureOfBusinessName) {
      // Set default value for natureOfBusinessName and NatureOfBusinessId
      formData.natureOfBusinessName = "Recruitment and Staffing";
      formData.NatureOfBusinessId = "ab783454-5772-4cf8-8ad4-92efccf22bd5";
    }

    const modifiedFormData = {
      ...formData,
      contactId: defaultContactId,
      NatureOfBusinessId:
        businessOptions.find(
          (item) => item.natureOfBusinessName === formData.natureOfBusinessName
        )?.id || formData.NatureOfBusinessId, // Use existing ID or default ID
    };

    const updatedData = [...data, modifiedFormData];

    // setData(updatedData);
    console.log("first", modifiedFormData);
    handleGridActionBegin({ requestType: "save", data: modifiedFormData });
  };

  const handleGridActionBegin = (args) => {
    console.log("first");
    if (args.requestType === "save") {
      const editedRow = args.data;
      console.log("edit", editedRow);
      let payload = {};
      if (editedRow.NatureOfBusinessId) {
        // Existing data (edit operation)
        payload = {
          ContactId: editedRow.contactId,
          Name: editedRow.name,
          Email: editedRow.email,
          OrgName: editedRow.orgName,
          Phone: editedRow.phone,
          Domain: editedRow.domain,
          Role: editedRow.role,
          IsActive: editedRow.isActive,
          Discount: editedRow.discounts,
          Linkedin: editedRow.linkedin,
          IsDp: editedRow.isDp,
          isRequested: editedRow.isRequested,
          NatureOfBusinessId: editedRow.NatureOfBusinessId, // Use existing ID
          NatureOfBusinessName: editedRow.natureOfBusinessName,
        };
      } else {
        // New data
        payload = {
          ContactId: editedRow.contactId,
          Name: editedRow.name,
          Email: editedRow.email,
          OrgName: editedRow.orgName,
          Phone: editedRow.phone,
          Domain: editedRow.domain,
          Role: editedRow.role,
          IsActive: editedRow.isActive,
          Discount: editedRow.discounts,
          Linkedin: editedRow.linkedin,
          IsDp: editedRow.isDp,
          isRequested: editedRow.isRequested,
          NatureOfBusinessId: businessOptions.find(
            (item) =>
              item?.natureOfBusinessName?.toLowerCase() ===
              editedRow.natureOfBusinessName.toLowerCase()
          )?.id,
          NatureOfBusinessName: editedRow.natureOfBusinessName,
        };
      }

      console.log("paysssload", payload);

      try {
        // Make the API call with the payload
        const response = axios.post(
          "https://eaa0d7ae.get1page.net/80ED5E2A",
          payload
        );

        if (response) {
          setRefetch(!refetch);
          const updatedName = payload.Name;
          console.log("00000", updatedName);
          toast.success(`Data for ${updatedName} updated successfully!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          toast.info('Fetching fresh data...', {
            position: toast.POSITION.TOP_RIGHT,
          });
        
          
         
            fetchData();
         
        }
      } catch (error) {
        console.error("Error calling API:", error);
      }
    }
  };
  const handleDpStatusChange = async (props) => {
    try {
      const updatedData = data.map((item) => {
        if (item.contactId === props.contactId) {
          return { ...item, isDp: !props.isDp };
        }
        return item;
      });
      setData(updatedData);
      const response = await axios.post(
        "https://cros-anywhere-proxy.onrender.com/https://eaa0d7ae.get1page.net/35904FF6?" +
          "id=" +
          props.contactId +
          "&isDpStatus=" +
          !props.isDp,
        {},
        {
          headers: {
            authToken: "F3EA4E07-A05C-4DE6-8212-00023891E33A",
          },
        }
      );
      if (response.status === 200) {
        setRefetch(!refetch);
      }
    } catch (error) {
      // Handle error
      toast.error("Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleIsRequestedStatusChange = async (props) => {
    try {
      const updatedData = data.map((item) => {
        if (item.contactId === props.contactId) {
          return { ...item, isRequested: !props.isRequested };
        }
        return item;
      });
      setData(updatedData);
      const response = await axios.post(
        "https://cros-anywhere-proxy.onrender.com/https://eaa0d7ae.get1page.net/D60BE341?" +
          "id=" +
          props.contactId +
          "&isRequestedStatus=" +
          !props.isRequested,
        {},
        {
          headers: {
            authToken: "F3EA4E07-A05C-4DE6-8212-00023891E33A",
          },
        }
      );
      if (response.status === 200) {
        setRefetch(!refetch);
      }
    } catch (error) {
      // Handle error
      toast.error("Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleSearch = async () => {
    const searchText = document.getElementsByClassName("searchtext")[0].value;

    if (searchText.length >= 3) {
      try {
        // Make the API call with the search text
        const response = await fetch(
          `https://cros-anywhere-proxy.onrender.com/https://eaa0d7ae.get1page.net/9BF080F3?searchText=${searchText}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const searchData = await response.json();
        setData(searchData);
        // Save the current search results as previousData
      } catch (error) {
        console.error("Error searching data:", error);
      }
    } else if (searchText.length < 3) {
      setData(previousData);
    }
  };

  const handleDiscountsChange = (rowDataItem, value) => {
    const updatedData = data.map((item) => {
      if (item.contactId === rowDataItem.contactId) {
        return { ...item, discounts: value };
      }
      return item;
    });
    setData(updatedData);
  };
  const [filterSettings, setFilterSettings] = useState();
  const filterModesData = ["Immediate", "OnEnter"];
  const onModeChange = (args) => {
    setFilterSettings({ mode: args.value });
  };
  const nob = [
    { countryName: "United States", countryId: "1" },
    { countryName: "Australia", countryId: "2" },
    { countryName: "India", countryId: "3" },
  ];
  const nobParams = {
    params: {
      actionComplete: () => false,
      dataSource: new DataManager(businessOptions),
      fields: { text: "natureOfBusinessName", value: "natureOfBusinessName" },
      query: new Query(),
    },
  };
  const columns = [
    {
      field: "name",
      headerText: "Name",
      allowEditing: true,
      width: 120,

      allowResizing: true,
      textAlign: "Center",
      allowFiltering: true,
    },

    {
      field: "email",
      headerText: "Email",
      allowEditing: true,
      width: 150,

      allowResizing: true,
      textAlign: "Center",
      allowFiltering: true,
    },
    {
      field: "linkedin",
      headerText: "LinkedIn",
      textAlign: "Center",
      allowEditing: true,
      allowResizing: true,
      width: 200,

      allowFiltering: true,
    },

    {
      field: "natureOfBusinessName",
      headerText: "Nature Of Business",
      allowEditing: false,
      textAlign: "center",
      width: 200,

      allowFiltering: true,
      editType: "dropdownedit",
      edit: nobParams,
    },
    {
      field: "NatureOfBusinessId",
      headerText: "Nature Of BusinessId",
    },

    {
      field: "isActive",
      headerText: "Is Active",
      width: 130,

      type: "boolean",
      allowFiltering: true,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={props.isActive}
            onChange={() => handleIsActiveToggle(props)}
            style={{
              transform: "scale(1.5)",
              marginRight: "5px",
              height: "0.65rem",
              width: "0.65rem",
            }}
          />
        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },
    {
      field: "isSigned",
      headerText: "Signed",
      width: 120,

      allowEditing: false,
      type: "boolean",
      allowFiltering: true,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={props.isSigned}
            disabled={true}
            style={{
              transform: "scale(1.5)",
              marginRight: "5px",
              height: "0.65rem",
              width: "0.65rem",
            }}
          />
        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },
    {
      field: "isUpload",
      headerText: "Upload ",
      width: 120,

      type: "boolean",
      allowEditing: false,
      allowFiltering: true,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={props.isUpload}
            disabled={true}
            style={{
              transform: "scale(1.5)",
              marginRight: "5px",
              height: "0.65rem",
              width: "0.65rem",
            }}
          />
        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },
    {
      field: "isDp",
      headerText: "DP",
      width: 100,

      type: "Boolean",
      allowFiltering: true,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={props?.isDp}
            onChange={() => handleDpStatusChange(props)}
            style={{
              transform: "scale(1.5)",
              marginRight: "5px",
              height: "0.65rem",
              width: "0.65rem",
            }}
          />
        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },
    {
      field: "isRequested",
      headerText: "Is Requested",
      width: 150,
      type: "Boolean",
      allowFiltering: true,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={props?.isRequested}
            onChange={() => handleIsRequestedStatusChange(props)}
            style={{
              transform: "scale(1.5)",
              marginRight: "5px",
              height: "0.65rem",
              width: "0.65rem",
            }}
          />
        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },
    {
      headerText: "Send Requested",
      width: 120,

      allowFiltering: false,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <button
  onClick={() => handleSendRequestedInvites(props.contactId)}
  className="custom-button"
  style={{
    ...buttonStyle,
    backgroundColor: !props.isRequested || props.isRequestedMailSent ? "grey" : "green",
    cursor: !props.isRequested || props.isRequestedMailSent ? "not-allowed" : "pointer",
  }}
  disabled={!props.isRequested || props.isRequestedMailSent}
>
  {props.isRequestedMailSent ? "Requested Mail Sent" : "Send Requested"}
</button>


        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },
    {
      headerText: "Send DP",
      width: 120,

      allowFiltering: false,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
         
         <button
  onClick={() => handleSendDPInvites(props.contactId)}
  className="custom-button"
  style={{
    ...buttonStyle,
    backgroundColor: props.isDpMailSent || !props.isDp ? "grey" : "green",
    cursor: props.isDpMailSent || !props.isDp ? "not-allowed" : "pointer",
  }}
  disabled={props.isDpMailSent || !props.isDp}
>
  {props.isDpMailSent ? "DP Mail Sent" : "Send DP"}
</button>
        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },

    {
      headerText: "Send Onboarding",
      width: 160,

      allowFiltering: false,
      template: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <button
  onClick={() => handleSendOnboarding(props.contactId)}
  style={{
    ...buttonStyle,
    backgroundColor: props.isOnboardingMailSent || !props.isActive ? "grey" : "green",
    cursor: props.isOnboardingMailSent || !props.isActive ? "not-allowed" : "pointer",
    fontFamily: "Public Sans, sans-serif",
    borderRadius: "4px",
    border: "none",
    width: "fit-content",
    boxSizing: "border-box",
    marginBottom: 0,
  }}
  disabled={props.isOnboardingMailSent || !props.isActive}
>
  {props.isOnboardingMailSent ? "Onboarding Mail Sent" : "Send Onboarding"}
</button>


        </div>
      ),
      isPrimaryKey: true,
      edit: null,
    },

    {
      field: "discounts",
      headerText: "Discounts",
      width: 200,

      allowFiltering: false,
      template: (props) => (
        <input
          type="text"
          value={props.discounts || ""} // Use empty string if discounts value is null or undefined
          onChange={(e) => handleDiscountsChange(props, e.target.value)}
          style={{ width: "100%" }} // Adjust width as needed
          placeholder="Discounts"
        />
      ),
    },
  ];
  const filteredColumns = columns.filter(
    (column) => column.field !== "NatureOfBusinessId"
  );
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    try {
      const csvData = await new Promise((resolve) => {
        Papa.parse(file, {
          complete: (result) => {
            resolve(result.data);
          },
        });
      });

      const headerMapping = {
        "Full Name": "name",
        Company: "orgName",
        "Job Title": "role",
        "Linked Url": "linkedin",
        "Work Email #1": "email", // Ensure email field is mapped
        // Add more mappings as needed
      };

      const jsonData = csvData.slice(1).map((values) => {
        const dataObject = {
          contactId: "00000000-0000-0000-0000-000000000000",
          name: "",
          email: "",
          DirectEmail: "",
          orgName: "",
          phone: "",
          domain: "",
          role: "",
          isActive: false,
          discounts: "",
          linkedin: "",
          isDp: false,
          isRequested: false,
        };

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        values.forEach((value, index) => {
          const trimmedValue = value.trim().replace(/"/g, "");

          const header = csvData[0][index];
          const field = headerMapping[header];

          if (field) {
            // Remove '^' from email addresses
            dataObject[field] =
              field === "email"
                ? trimmedValue.replace(/\^/g, "")
                : trimmedValue;
          } else if (
            header === "Work Email #1" ||
            (header === '"Work Email #1"' && emailRegex.test(trimmedValue))
          ) {
            dataObject.email = trimmedValue;
          } else if (
            header === "Direct Email #1" ||
            header === '"Direct Email #1"'
          ) {
            dataObject.DirectEmail = trimmedValue;
          }
        });

        return dataObject;
      });

      // Check if any record is missing email
      const recordsWithMissingEmail = jsonData.filter(
        (record) => !record.email || record.email.trim() === ""
      );

      if (recordsWithMissingEmail.length > 0) {
        toast.error(
          `${recordsWithMissingEmail.length} record(s) are missing email. Please provide email for all records.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        return;
      }

      // Continue with the upload process for valid records
      const response = await axios.post(
        "https://eaa0d7ae.get1page.net/F1AD2A8A",
        jsonData
      );
      setRefetch(!refetch);

      toast.success(`${jsonData.length} entries uploaded successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error processing CSV:", error);
      // Handle error if CSV processing fails
    }
  };

  // Function to handle "View Discounts" button click

  const handleSendDPInvites = async (contactId) => {
    try {
     
      await axios.post(
        `https://eaa0d7ae.get1page.net/1802E370?miContactId=${contactId}`
      );
      // Handle success or perform any necessary action after sending DP invites
      setRefetch(!refetch);
      toast.success("Successfully send DP invite mail", {
        position: toast.POSITION.TOP_RIGHT,
      });
     

    } catch (error) {
      // Handle error
    }
  };

  const handleSendRequestedInvites = async (contactId) => {
    try {
     
      await axios.post(
        `https://eaa0d7ae.get1page.net/A93B048C?id=${contactId}`
      );
      // Handle success or perform any necessary action after sending DP invites
      setRefetch(!refetch);
      toast.success("Successfully sent requested invite mail", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // Handle error
    }
  };
  const handleSendOnboarding = async (contactId) => {
    try {
    
      await axios.post(
        `https://eaa0d7ae.get1page.net/B790E59D?miContactId=${contactId}`
      );
     
      setRefetch(!refetch);
      // Reset button color after a short delay

      toast.success("Successfully sent onboarding mail", {
        position: toast.POSITION.TOP_RIGHT,
      });
     

    } catch (error) {
      // Handle error
    }
  };
  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        "https://moukha.backend.get1page.net/api/v3/nature-of-business"
      );
      const updatedStructureOfNob = response.data.data.map((item) => ({
        ...item,
        natureOfBusinessName: item.name,
      }));
      setBusinessOptions(updatedStructureOfNob);
      console.log("first business data", response.data.data);
    } catch (error) {
      console.error("Error fetching business options:", error);
    }
  };
  const fetchData = async (page) => {
    try {
      setFetchingData(true);

      // Make the API call with pagination parameters
      const response = await fetch(
        `https://cros-anywhere-proxy.onrender.com/https://eaa0d7ae.get1page.net/6FC80F06`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add other headers if required
          },
          body: JSON.stringify({
            pageNumber: page,
            totalItem: "100",
          }),
        }
      );

      const fetchedData = await response.json();
      const newData = page === 1 ? fetchedData : [...data, ...fetchedData];
      setData(newData);
      setLoading(false);
      setFetchingData(false);
      setCurrentPage(page);
      setPreviousData(newData);
      setFetchingData(false);
    } catch (error) {
      setLoading(false);
      setFetchingData(false);
      // Handle error
    }
  };

  // const handleDataBound = (args) => {
  //   const grid = args?.sender;
  //   const lastVisibleRowIndex = grid?.getRows().length - 1;
  //   const totalRecordCount = grid?.dataSource?.totalRecordsCount;

  //   if (lastVisibleRowIndex === totalRecordCount - 1) {
  //     // Fetch the next page when reaching the last row
  //     const nextPage = currentPage + 1;
  //     fetchData(nextPage);
  //   }
  // };

  useEffect(() => {
    // Initial data fetch
    fetchData(1); // Fetch data for the first page
    fetchData1();
  }, [refetch]);
  // useEffect(() => {
  //   // Filter data based on search term
  //   const filtered = data.filter((item) =>
  //     Object.values(item).some(
  //       (fieldValue) =>
  //         fieldValue !== null &&
  //         fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //     )
  //   );
  //   setFilteredData(filtered);
  // }, [data, searchTerm]);

  const handleIsActiveToggle = async (rowDataItem) => {
    try {
      const updatedData = data.map((item) => {
        if (item.contactId === rowDataItem.contactId) {
          return { ...item, isActive: !rowDataItem.isActive };
        }
        return item;
      });
      setData(updatedData);
      const response = await axios.post(
        "https://eaa0d7ae.get1page.net/4A9C314C",
        {
          contactId: rowDataItem.contactId,
          isActive: !rowDataItem.isActive,
        }
      );

      if (response.status === 200) {
        const updatedData = data.map((item) => {
          if (item.contactId === rowDataItem.contactId) {
            return { ...item, isActive: !rowDataItem.isActive };
          }
          return item;
        });
        setData(updatedData);
        setRefetch(!refetch);
      }
    } catch (error) {
      // Handle error
    }
  };

  const loadingIndicator = { indicatorType: "Shimmer" };
  const FilterOptions = {
    type: "Menu",
  };

  const handleSearchKeyDown = (event) => {
    // Check if the Backspace key is pressed
    if (event.key === "Backspace") {
      // Handle Backspace key press, e.g., by triggering search again
      handleSearch();
    }
  };
console.log("businessOptions",businessOptions)
  return (
    <div id="grid-container" className="grid-container">
      {showModal && (
        <AddRowModal
          isOpen={showModal}
          onClose={handleCloseModal}
          onSave={handleSaveModal}
          businessOptions={businessOptions}
        />
      )}
      {showFileUploadModal && (
        <FileUploadModal
          isOpen={showFileUploadModal}
          onClose={handleCloseFileUploadModal}
          onFileUpload={handleFileUpload}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      )}
      <div>
        <ToastContainer position="top-right" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <button onClick={handleAddRow} className="add-row-button">
            Add New User
          </button>
          <label
            htmlFor="fileInput"
            className="custom-file-upload"
            onClick={handleShowFileUploadModal}
          >
            Upload CSV File
          </label>
        </div>
        <div className="search-input-container">
          <span className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </span>
          <input
            type="text"
            className="searchtext"
            onChange={handleSearch}
            onKeyDown={handleSearchKeyDown}
            placeholder="Global Search"
          />
          <span className="e-float-line" />
        </div>

        <GridComponent
          allowPaging={true}
          height={"52vh"}
          width={"100%"}
          actionBegin={actionBegin}
          actionComplete={actionComplete}
          pageSettings={pageOptions}
          dataSource={data}
          allowSorting={true}
          className="grid"
          gridLines="Both"
          columns={filteredColumns}
          toolbar={["Search"]}
          loadingIndicator={loadingIndicator}
          editSettings={editOptions}
          allowMultiSorting={true}
          allowResizing={true}
          allowGrouping={true}
          groupSettings={groupSettings}
          allowFiltering={true}
          filterSettings={FilterOptions}
        >
          <Inject
            services={[Edit, Toolbar, Sort, Page, Resize, Group, Filter]}
          />
        </GridComponent>
      </div>
    </div>
  );
};

export default SampleGridComponent;
