// FileUploadModal.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../UserTable/modal1.css";

import { toast, ToastContainer } from "react-toastify";
import Papa from "papaparse";
const FileUploadModal = ({ isOpen, onClose, onFileUpload,setRefetch,refetch }) => {
  const [businessOptions, setBusinessOptions] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const inputFile = useRef(null);


    // Fetch data for dropdown from the API
    useEffect(() => {
      // Fetch data for dropdown from the API
      const fetchData = async () => {
        try {
          const response = await axios.get(
            "https://moukha.backend.get1page.net/api/v3/nature-of-business"
          );
          const businessData = response.data.data;
    
          setBusinessOptions(businessData);
    
          // Set the first business as the default selected
          if (businessData.length > 0) {
            setSelectedBusiness(businessData[0].id);
          }
    
          console.log("first", response.data.data);
        } catch (error) {
          console.error("Error fetching business options:", error);
        }
      };
    
      fetchData();
    }, []); // Run only once when the component mounts
    
  const handleUpload = () => {
    const file = inputFile.current.files[0];

    if (file) {
      handleFileUpload({ target: { files: [file] } });
    }

    onClose();
  };

  const handleBusinessChange = (event) => {
    const id = event?.target?.value;
    const name = event?.target?.options[event?.target?.selectedIndex]?.text;
  
    setSelectedId(id);
    setSelectedName(name);
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      return;
    }
  
    try {
      const csvData = await new Promise((resolve) => {
        Papa.parse(file, {
          complete: (result) => {
            resolve(result.data);
          },
        });
      });
  
      const headerMapping = {
       "First Name":"name",
       "Last name": "lastName", 
       Company: "orgName",
        "Job Title": "role",
        "Linked Url": "linkedin",
        // Add more mappings as needed
      };
  
      const jsonData = csvData.slice(1).map((values) => {
        const dataObject = {
          contactId: "00000000-0000-0000-0000-000000000000",
          name: "",
          email: "",
          DirectEmail: "",
          orgName: "",
          phone: "",
          domain: "",
          role: "",
          isActive: false,
          discounts: "",
          linkedin: "",
          isDp: false,
          NatureOfBusinessId: selectedId || "ab783454-5772-4cf8-8ad4-92efccf22bd5",
      NatureOfBusinessName: selectedName || "Recruitment and Staffing",
        };
  
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
        values.forEach((value, index) => {
          const trimmedValue = value.trim().replace(/"/g, "");
  
          const header = csvData[0][index];
          const field = headerMapping[header];
  
          if (field) {
           
              {          dataObject[field] =
              field === "email"
                ? trimmedValue.replace(/\^/g, "")
                : trimmedValue;
              }
          } else if (
            header === "Work Email #1"  || header === "Email" ||
            (header === '"Work Email #1"' && emailRegex.test(trimmedValue))
          ) {
            dataObject.email = trimmedValue;
          } else if (
            header === "Direct Email #1" ||
            header === '"Direct Email #1"'
          ) {
            dataObject.DirectEmail = trimmedValue;
          }
        });
  
        return dataObject;
      });
  
      // Check if any records with missing email exist
      const recordsWithMissingEmail = jsonData.filter(
        (record) => record.email.trim() === "" && record.DirectEmail.trim() === ""
      );
  
      // Check if any records with missing email exist
      if (recordsWithMissingEmail.length > 1) {
        const totalMissingEmails = recordsWithMissingEmail.length;
        
        // Log details of records with missing email
        recordsWithMissingEmail.forEach((record) => {
          console.log("Record with missing email:", record);
        });
      
        // Show toast with the total number of records with missing email
        toast.error(
          `${totalMissingEmails} record(s) with missing email/Direct email. Uploading the rest...`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
  
      // Filter records with either email or DirectEmail
      const validRecords = jsonData.filter(
        (record) =>
          record.email.trim() !== "" || record.DirectEmail.trim() !== ""
      );
  
      // Check if any valid records exist
      if (validRecords.length > 0) {
        validRecords.forEach((record) => {
          if (record.email.trim() !== "") {
            const emailParts = record.email.split("@");
            record.domain = emailParts.length === 2 ? emailParts[1] : "";
          } else if (record.DirectEmail.trim() !== "") {
            const directEmailParts = record.DirectEmail.split("@");
            record.domain = directEmailParts.length === 2 ? directEmailParts[1] : "";
          }
        });
        // Continue with the upload process for valid records
        const response = await axios.post(
          "https://eaa0d7ae.get1page.net/F1AD2A8A",
          validRecords
        );
  
        setRefetch(!refetch);
  
        toast.success(
          `${validRecords.length} entries uploaded successfully`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        toast.error(
          `No records found with either email or DirectEmail. Please provide email for at least one record.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } catch (error) {
      console.error("Error processing CSV:", error);
      // Handle error if CSV processing fails
      toast.error(
        `Error processing CSV. Please check the file format and try again.`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

 
  return (
    <div className={`modal ${isOpen ? "show" : "hide"}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>File Upload</h2>
        {/* Dropdown for nature of business */}
        <label htmlFor="businessDropdown">Nature of Business:</label>
        <select
  id="businessDropdown"
  value={selectedId}
  onChange={handleBusinessChange}
>
  <option value="" disabled>
  Recruitment and Staffing
  </option>
  {businessOptions?.map((business) => (
    <option key={business?.id} value={business?.id}>
      {business?.name}
    </option>
  ))}
</select>
        {/* File input for CSV */}
        <label htmlFor="fileInput">Select CSV File:</label>
        <input type="file" accept=".csv" ref={inputFile} />

        <div className="modal-buttons">
          <button style={{ marginRight: '10px' }} onClick={onClose}>Close</button>
          <button onClick={handleUpload}>Upload</button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;
