import React from "react";

import "../../styles/TopBar/TopBar.css"; // Import the CSS file for Top Bar
import mauka from "../../Moukha.png";
const TopBar = () => {
  return (
    <div className="top-bar">
      <img src={mauka} alt="Logo" className="logo" />
      <span className="brand-name">Moukha</span>
      <img
        src="https://cdn.vectorstock.com/i/preview-1x/17/61/male-avatar-profile-picture-vector-10211761.jpg"
        alt="Profile"
        className="profile-img"
      />
    </div>
  );
};

export default TopBar;
